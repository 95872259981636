<template>
	<DataTable :value="fieldList" :paginator="true" class="p-datatable-gridlines" :rows="page_size" dataKey="id"
		:rowHover="true"  :loading="loadingField" :lazy="true" :totalRecords="totalCount" responsiveLayout="scroll" @page="onFieldPage">

		<template #header>
			<div class="grid grid-nogutter">
				<div class="col-6 text-left">
					<!-- <Button label="返回" icon="pi pi-chevron-left" class="p-button-primary mr-2" @click="goBack" /> -->
					<Button label="新增场地" icon="pi pi-plus" class="p-button-secondary mr-2"
						@click="openAddField('add', '')" />
				</div>
				<div class="col-6 text-right">
					<span class="p-input-icon-left mb-2">
						<i class="pi pi-search" />
						<InputText v-model="filtersField['global'].value" placeholder="关键词搜索" style="width: 100%" />
					</span>
				</div>
				<Dialog :header="lodingtitle" v-model:visible="displayAddField" :style="{ width: '25vw' }"
					:modal="true">
					<div class="field p-fluid">
						<label for="enterpriseName">所属企业</label>
						<InputText id="enterpriseName" type="text" class="p-error" :disabled="true"
							v-model="fatherInfo.name" />
					</div>
					<div class="field p-fluid">
						<label for="fieldClass">场地分区</label>
						<InputText id="fieldClass" type="text" class="p-error" v-model="fieldForm.plant_class"
							aria-describedby="fieldClass-help" />
						<small id="fieldClass-help" class="p-error"
							v-show="addFieldClassError">请输入正确的场地分区，由中文、英文或者数字组成</small>
					</div>
					<div class="field p-fluid">
						<label for="fieldName">场地名称</label>
						<InputText id="fieldName" type="text" class="p-error" v-model="fieldForm.name"
							aria-describedby="fieldName-help" />
						<small id="fieldName-help" class="p-error"
							v-show="addFieldNameError">请输入正确的场地名称，由中文、英文或者数字组成</small>
					</div>
					<div class="field p-fluid">
						<label for="fieldContactsName">联系人</label>
						<InputText id="fieldContactsName" type="text" class="p-error" v-model="fieldForm.contacts"
							aria-describedby="fieldContactsName-help" />
						<small id="fieldContactsName-help" class="p-error"
							v-show="addFieldContactsNameError">请输入正确的联系人，由中文、英文或者数字组成</small>
					</div>
					<div class="field p-fluid">
						<label for="fieldContactsNumber">联系方式</label>
						<InputText id="fieldContactsNumber" type="text" class="p-error"
							v-model="fieldForm.contact_number" aria-describedby="fieldContactsNumber-help" />
						<small id="fieldContactsNumber-help" class="p-error"
							v-show="addFieldContactsNumberError">请输入正确的手机号码</small>
					</div>
					<template #footer>
						<Button label="取消" icon="pi pi-times" @click="displayAddField = false" class="p-button-text" />
						<Button label="确定" icon="pi pi-check" @click="doAddField" autofocus />
					</template>
				</Dialog>
			</div>
		</template>
		<template #empty>
			No customers found.
		</template>
		<template #loading>
			Loading customers data. Please wait.
		</template>
		<Column field="name" header="ID" style="min-width:5rem">
			<template #body="{ data }">
				{{ data.id }}
			</template>
		</Column>
		<Column field="name" header="场地名称" style="min-width:12rem">
			<template #body="{ data }">
				{{ data.site_name }}
			</template>
		</Column>
		<Column header="联系人" style="min-width:8rem">
			<template #body="{ data }">
				{{ data.contacts }}
			</template>
		</Column>
		<Column header="联系方式" style="min-width:9rem">
			<template #body="{ data }">
				{{ data.contact_number }}
			</template>
		</Column>
		<Column header="操作" style="min-width:6rem">
			<template #body="{ data }">
				<span class="p-input-icon-left">
					<Button label="区域" class="p-button-primary p-button-outlined mr-1"
						@click="openRegionList(data)" />
					<Button label="编辑" class="p-button-success p-button-text mr-1"
						@click="openAddField('edit', data)" />
					<Button label="删除" class="p-button-danger p-button-text mr-1" @click="delField(data, $event)" />

					<!-- 区域管理列表对话框 -->
					<Dialog header="区域管理" v-model:visible="displayRegionList" :style="{ width: '50vw' }" :modal="true">

						<DataTable :value="reigonList" :paginator="true" class="p-datatable-gridlines" :rows="15"
							dataKey="id" :rowHover="true" v-model:filters="filtersRegion" filterDisplay="menu"
							:loading="loadingField" :filters="filtersRegion" responsiveLayout="scroll"
							:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">

							<template #header>
								<div class="grid grid-nogutter">
									<div class="col-6 text-left">
										<Button label="新增区域" icon="pi pi-plus" class="p-button-secondary mr-2"
											@click="openAddRegion('add', '')" />
									</div>
									<div class="col-6 text-right">
										<span class="p-input-icon-left mb-2">
											<i class="pi pi-search" />
											<InputText v-model="filtersRegion['global'].value" placeholder="关键词搜索"
												style="width: 100%" />
										</span>
									</div>

									<!-- 添加区域对话框 -->
									<Dialog :header="regionOpName" v-model:visible="displayAddRegion"
										:style="{ width: '30vw' }" :modal="true">
										<div class="field p-fluid">
											<label for="regionName">区域名称</label>
											<InputText id="regionName" type="text" class="p-error"
												v-model="regionForm.region" aria-describedby="regionName-help" />
											<small id="regionName-help" class="p-error"
												v-show="addRegionNameError">请输入正确的区域名称，由中文、英文或者数字组成</small>
										</div>
<!-- 
										<div class="field p-fluid">
											<label for="regionName">热图区</label>
											<div class="card">
												<div class="grid">
													<div class="col-12">
														<div class="flex justify-content-center">
															<img :src="regionForm.hotImage"
																class="w-full shadow-2 my-0 mx-0" />
														</div>
													</div>
												</div>

												<div class="grid">
													<div class="col-3"></div>
													<div class="col-3 text-right">
														<FileUpload url="http://175.27.161.215:8000/api/upload/image"
															@upload="onUpload('hot', $event)" :multiple="false"
															accept="image/*" name="file" mode="basic" :auto="true"
															:maxFileSize="1000000" chooseLabel="上传" />
													</div>
													<div class="col-3 text-left">
														<Button label="删除" icon="pi pi-trash"
															class="p-button-secondary mr-2" />
													</div>
													<div class="col-3"></div>
												</div>
											</div>
										</div>

										<div class="field p-fluid">
											<label for="regionName">图标</label>
											<div class="card">
												<div class="grid">
													<div class="col-12">
														<div class="flex justify-content-center">
															<img :src="regionForm.uncheckImage"
																class="w-full shadow-2 my-0 mx-0" />
														</div>
													</div>
												</div>

												<div class="grid">
													<div class="col-3"></div>
													<div class="col-3 text-right">
														<FileUpload url="http://175.27.161.215:8000/api/upload/image"
															@upload="onUpload('uncheck', $event)" :multiple="false"
															accept="image/*" name="file" mode="basic" :auto="true"
															:maxFileSize="1000000" chooseLabel="上传" />
													</div>
													<div class="col-3 text-left">
														<Button label="删除" icon="pi pi-trash"
															class="p-button-secondary mr-2" />
													</div>
													<div class="col-3"></div>
												</div>
											</div>
										</div>

										<div class="field p-fluid">
											<label for="regionName">选中图标</label>
											<div class="card">
												<div class="grid">
													<div class="col-12">
														<div class="flex justify-content-center">
															<img :src="regionForm.checkImage"
																class="w-full shadow-2 my-0 mx-0" />
														</div>
													</div>
												</div>

												<div class="grid">
													<div class="col-3"></div>
													<div class="col-3 text-right">
														<FileUpload url="http://175.27.161.215:8000/api/upload/image"
															@upload="onUpload('check', $event)" :multiple="false"
															accept="image/*" name="file" mode="basic" :auto="true"
															:maxFileSize="1000000" chooseLabel="上传" />
													</div>
													<div class="col-3 text-left">
														<Button label="删除" icon="pi pi-trash"
															class="p-button-secondary mr-2" />
													</div>
													<div class="col-3"></div>
												</div>
											</div>
										</div> -->

										<template #footer>
											<Button label="取消" icon="pi pi-times" @click="displayAddRegion = false"
												class="p-button-text" />
											<Button label="确定" icon="pi pi-check" @click="doAddRegion" autofocus />
										</template>
									</Dialog>
								</div>
							</template>
							<template #empty>
								No customers found.
							</template>
							<template #loading>
								Loading customers data. Please wait.
							</template>
							<Column field="name" header="ID" style="min-width:5rem">
								<template #body="{ data }">
									{{ data.id }}
								</template>
							</Column>
							<Column field="name" header="区域名称" style="min-width:15rem">
								<template #body="{ data }">
									{{ data.region_name }}
								</template>
							</Column>
							<Column field="name" header="所属场地" style="min-width:10rem">
								<template #body="{ data }">
									{{ data.site_name }}
								</template>
							</Column>
							<Column header="操作" style="min-width:5rem">
								<template #body="{ data }">
									<span class="p-input-icon-left">
										<!-- <Button label="位置" class="p-button-primary p-button-outlined mr-1"
											@click="openPositionList(data)" /> -->
										<Button label="编辑" class="p-button-success p-button-text mr-1"
											@click="openAddRegion('edit', data)" />
										<Button label="删除" class="p-button-danger p-button-text mr-1"
											@click="delRegion(data, $event)" />

										<Dialog header="位置管理" v-model:visible="displayPosList"
											:style="{ width: '50vw' }" :modal="true">

											<DataTable :value="positionList" :paginator="true"
												class="p-datatable-gridlines" :rows="15" dataKey="id" :rowHover="true"
												v-model:filters="filtersPos" filterDisplay="menu" :loading="loadingPos"
												:filters="filtersPos" responsiveLayout="scroll"
												:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">

												<template #header>
													<div class="grid grid-nogutter">
														<div class="col-6 text-left">
															<Button label="新增位置" icon="pi pi-plus"
																class="p-button-secondary mr-2"
																@click="openAddPos('add', '')" />
														</div>
														<div class="col-6 text-right">
															<span class="p-input-icon-left mb-2">
																<i class="pi pi-search" />
																<InputText v-model="filtersPos['global'].value"
																	placeholder="关键词搜索" style="width: 100%" />
															</span>
														</div>

														<!-- 添加位置对话框 -->
														<Dialog :header="posOpName" v-model:visible="displayAddPos"
															:style="{ width: '25vw' }" :modal="true">
															<div class="field p-fluid">
																<label for="regionName">所属区域</label>
																<InputText id="regionName" type="text" class="p-error"
																	:disabled="true"
																	v-model="positionInfo.region_name" />
															</div>
															<div class="field p-fluid">
																<label for="posName">位置名称</label>
																<InputText id="posName" type="text" class="p-error"
																	v-model="positionForm.name"
																	aria-describedby="fieldClass-help" />
																<small id="fieldClass-help" class="p-error"
																	v-show="addFieldClassError">请输入正确的场地分区，由中文、英文或者数字组成</small>
															</div>
															<template #footer>
																<Button label="取消" icon="pi pi-times"
																	@click="displayAddPos = false"
																	class="p-button-text" />
																<Button label="确定" icon="pi pi-check" @click="doAddPos"
																	autofocus />
															</template>
														</Dialog>
													</div>
												</template>
												<template #empty>
													No customers found.
												</template>
												<template #loading>
													Loading customers data. Please wait.
												</template>
												<Column field="name" header="ID" style="min-width:5rem">
													<template #body="{ data }">
														{{ data.id }}
													</template>
												</Column>
												<Column field="name" header="位置名称" style="min-width:15rem">
													<template #body="{ data }">
														{{ data.region_name }}
													</template>
												</Column>
												<Column header="操作" style="min-width:5rem">
													<template #body="{ data }">
														<span class="p-input-icon-left">
															<Button label="编辑"
																class="p-button-success p-button-text mr-1"
																@click="openAddPos('edit', data)" />
															<Button label="删除"
																class="p-button-danger p-button-text mr-1"
																@click="delPos(data, $event)" />
														</span>
													</template>
												</Column>
											</DataTable>
										</Dialog>
									</span>
								</template>
							</Column>
						</DataTable>
					</Dialog>
				</span>
			</template>
		</Column>
	</DataTable>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
	data() {
		return {
			//-----------------------------------------------------------
			fieldList: null,
			title: '',
			fatherInfo: {},
			// 场地列表分页
			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 15, // 每页条数

			fieldForm: {
				name: '',
				contacts: '',
				contact_number: '',
				plant_class: ''
			},
			lodingtitle: '新增场地',

			addFieldClassError: false,
			addFieldNameError: false,
			addFieldContactsNameError: false,
			addFieldContactsNumberError: false,

			displayRegionList: false,
			reigonList: [],
			// 区域列表分页
			regionPage: 0, // 数据总数
			region_index: 1, // 当前页数
			region_size: 10, // 每页条数

			regionTitle: '新增区域',
			displayAddRegion: false,
			regionForm: {
				id: '',
				region: '',
				hotImage: '',
				uncheckImage: '',
				checkImage: ''
			},
			regionOpName: '',

			filtersField: null,
			filtersRegion: null,
			displayAddGroup: false,
			displayAddField: false,
			displayPosList: false,
			displayAddPos: false,

			positionInfo: {},
			positionList: [],
			positionPage: 0,
			positionTotal: 0,
			filtersPos: null,
			loadingPos: true,
			posOpName: '新增位置',

			positionForm: {
				id: '',
				name: ''
			},

			//-----------------------------------------------------------

			filters2: {},
			loadingField: true,
			loadingRegion: true,
			idFrozen: false,
			products: null,
			company_id: -1,
			company_name: '',
			group_id: -1,
			group_name: '',
			groupBreadIndex: -1,
			backIndex: -1,
		}
	},
	created() {
		this.initFilters();

		this.company_id = this.$route.query.ent_id;
		this.company_name = this.$route.query.ent_name;
		this.group_id = this.$route.query.cluster_id;
		this.group_name = this.$route.query.cluster_name;
		this.groupBreadIndex = parseInt(this.$route.query.bread_index);
		this.backIndex = parseInt(this.$route.query.back_index);

		if (this.group_id == ''
			|| this.group_id == -1) {
			this.fatherInfo.id = this.company_id;
			this.fatherInfo.name = this.company_name;
		}
		else {
			this.fatherInfo.id = this.group_id;
			this.fatherInfo.name = this.group_name;
		}

		this.getFieldList();
	},
	mounted() {

	},
	watch: {
		displayAddRegion: function (newVal, oldVal) {
			if (newVal === false && oldVal === true) {
				this.regionForm.hotImage = '';
				this.regionForm.checkImage = '';
				this.regionForm.uncheckImage = '';
			}
		}
	},
	methods: {
		initFilters() {
			this.filtersField = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
			this.filtersRegion = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
			this.filtersPos = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},
		// 首页列表
		getFieldList() {
			this.loadingField = true;
			this.$http('/api/admin/partition/index', {
				enterprise_id: this.fatherInfo.id,
				plant_class: '',
				//page: this.page_index,
				//page_size: this.page_size,
				page_size: 100,		//搞一个比较大的数，尽量一次显示完，不做懒加载
			}).then(res => {
				if (res.code == 200) {
					res.data.data.map(e => {
						e.status = e.status == 0
					})
					this.fieldList = res.data.data
					this.totalCount = res.data.total
					//this.page_size = res.data.per_page

					console.log(this.fieldList)
				}
				this.loadingField = false;
			})
		},
		getRegionList() {
			this.loadingRegion = true;
			this.$http('/api/admin/region/index', {
				enterprise_partition_id: this.siteId,
				//page: this.region_index,
				page_size: 100,		//搞一个比较大的数，尽量一次显示完，不做懒加载
			}).then(res => {
				if (res.code == 200) {
					this.reigonList = res.data.data
					this.regionPage = res.data.total
					this.region_size = res.data.per_page

					console.log(this.reigonList)
				} else {
					this.$message.error(res.msg)
				}
				this.loadingRegion = false;
			})
		},

		// 获取区域下级位置列表
		getPositionList() {
			this.loadingPos = true;
			this.$http('/api/admin/region/indexx', {
				top_id: this.positionInfo.id,
				page: this.positionPage
			}).then(res => {
				if (res.code == 200) {
					this.positionList = res.data.data
					this.positionTotal = res.data.total
					this.loadingPos = false;					
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		goBack() {
			var _grp_bread_items = JSON.parse(window.sessionStorage.getItem('groupBreadItems'));

			if (this.groupBreadIndex != -1) {
				_grp_bread_items = _grp_bread_items.splice(0, this.groupBreadIndex);
				window.sessionStorage.setItem('groupBreadItems', JSON.stringify(_grp_bread_items));
			}

			if (this.backIndex != -1) {
				var _bread_item = _grp_bread_items[this.backIndex];
				window.sessionStorage.setItem('groupBreadIndex', _bread_item.params.bread_index);

				this.$router.push({
					path: _bread_item.to,
					query: _bread_item.params
				});

				this.$emit('updateData');
			}
		},

		// viewFieldArea(company, group, field, company_name, group_name, field_name) {
		// 	this.$router.push({
		// 		name: 'groupfieldareaconfig',
		// 		params: {
		// 			comp_id: company,
		// 			grp_id: group,
		// 			fid_id: field,
		// 			comp_name: company_name,
		// 			grp_name: group_name,
		// 			fid_name: field_name
		// 		}
		// 	});
		// },

		openAddField(type, item) {
			if (type == 'add') {
				this.fieldForm = {
					name: '',
					plant_class: '',
					contacts: '',
					id: '',
					contact_number: ''
				}
				this.lodingtitle = '新增场地'
			} else {
				this.fieldForm = {
					name: item.site_name,
					plant_class: item.plant_class,
					contacts: item.contacts,
					contact_number: item.contact_number,
					id: item.id
				}
				this.lodingtitle = '编辑场地'
			}
			this.displayAddField = true;
		},
		doAddField() {
			this.$http('/api/admin/partition/create', {
				site_name: this.fieldForm.name,
				plant_class: this.fieldForm.plant_class,
				contacts: this.fieldForm.contacts,
				contact_number: this.fieldForm.contact_number,
				enterprise_id: this.fatherInfo.id,
				id: this.fieldForm.id
			}).then(res => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功' , life: 3000 });
					this.getFieldList()
					this.displayAddField = false
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 3000  });
				}
			})
		},
		delField(item, event) {
			this.$confirm.require({
				message: '确定要删除这个场地吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/partition/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功' , life: 3000 });
							this.getFieldList();
						} else {
							this.$toast.add({ severity: 'error', summary: '操作失败' , life: 3000 });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},

		openRegionList(item) {
			console.log(item)
			this.siteId = item.id;
			this.displayRegionList = true;
			this.getRegionList();
		},

		openAddRegion(type, item) {
			if (type == 'add') {
				this.regionOpName = '新增区域'
				this.regionForm = {
					id: '',
					region: '',
					hotImage: '',
					uncheckImage: '',
					checkImage: ''
				}
			} else {
				this.regionOpName = '编辑区域'
				this.regionForm = {
					region: item.region_name,
					id: item.id,
					hotImage: item.hot_img,
					uncheckImage: item.icon,
					checkImage: item.check_icon
				}
			}
			this.displayAddRegion = true
		},

		onUpload(type, event) {
			let resp = JSON.parse(event.xhr.response);
			if (type === 'hot') {
				this.regionForm.hotImage = resp.data;
			}
			else if (type === 'uncheck') {
				this.regionForm.uncheckImage = resp.data;
			}
			else if (type === 'check') {
				this.regionForm.checkImage = resp.data;
			}

			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'File Uploaded', life: 2000 });
		},

		// 确认添加区域
		doAddRegion() {
			this.$http('/api/admin/region/create', {
				enterprise_partition_id: this.siteId,
				region_name: this.regionForm.region,
				id: this.regionForm.id,
				top_id: '0',
				hot_img: this.regionForm.hotImage,
				icon: this.regionForm.uncheckImage,
				check_icon: this.regionForm.checkImage
			}).then(res => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功', life: 1000 });
					this.getRegionList()
					this.displayAddRegion = false
				} else {
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: res.msg, life: 3000 });
				}
			})
		},

		// 区域删除 
		delRegion(item, event) {
			this.$confirm.require({
				message: '确定要删除这个区域吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/region/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功', life: 3000  });
							this.getRegionList();
						} else {
							this.$toast.add({ severity: 'error', summary: '操作失败', life: 3000  });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},
		openPositionList(item) {
			console.log(item)
			this.displayPosList = true
			this.positionInfo = item
			this.getPositionList()
		},
		// 新增区域下级位置
		openAddPos(type, item) {
			console.log(type, item)
			if (type == 'add') {
				this.posOpName = '新增位置';
				this.positionForm = {
					name: '',
					id: '',
				}
			} else {
				this.posOpName = '编辑位置';
				this.positionForm = {
					name: item.region_name,
					id: item.id
				}
			}
			this.displayAddPos = true
		},

		// 确认新增区域下级位置
		doAddPos() {
			this.$http('/api/admin/region/create', {
				enterprise_partition_id: this.siteId,
				region_name: this.positionForm.name,
				id: this.positionForm.id,
				top_id: this.positionInfo.id
			}).then(res => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功', life: 3000  });
					this.getPositionList();
					this.displayAddPos = false;
				} else {
					this.$toast.add({ severity: 'error', summary: '操作失败', life: 3000  });
				}
			})
		},

		// 删除区域下的位置
		delPos(item, event) {
			this.$confirm.require({
				message: '确定要删除这个位置吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/region/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功' , life: 3000 });
							this.getPositionList();
						} else {
							this.$toast.add({ severity: 'error', summary: '操作失败' , life: 3000 });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},



		onFieldPage(event) {
			//this.cars = //load the data between (event.first) and (event.first + event.rows) from a remote datasource
			//console.log(event);
			this.page_index = event.page + 1;
			this.getFieldList();
		},

		openAddGroup() {
			this.displayAddGroup = true;
		},
		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeCompany(id) {
			console.print('remove:' + id);
		},
		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	}
}
</script>